import { useEffect, useState } from "react";
import { Button, Grid, Paper, Typography } from "@mui/material";
import ReactJson from "react-json-view";
import { useDispatch } from "react-redux";
import JSONEditor from "../jsonViewer";
import { createForm, getAllForms } from "../../store/thunk/formThunk";
import { getVerifyAuth } from "../../store/thunk/authThunk";

const JsonPreview = (props: any) => {
  const { uiNodes, nodes, formState, setFormState, formList } = props;
  const dispatch: any = useDispatch();
  const [uiNodes1, setUiNodes1] = useState(uiNodes);
  const [data, setData] = useState<any>(null);
  const [formattedData, setFormattedData] = useState<string>();
  const [copyLegend, setCopyLegend] = useState("Copy");
  const [copyColor, setCopyColor] = useState("bg-blue-500 hover:bg-blue-700");
  const [hasStringified, setHasStringified] = useState(false);
  const [isValidJSON, setIsValidJSON] = useState(true);
  const [error, setError] = useState<any>([]);
  const [errors, setErrors] = useState<any>({});

  useEffect(() => {
    setFormattedData(JSON.stringify(nodes));
  }, [nodes]);

  const beautifyJSON = () => {
    if (!isValidJSON) {
      alert("Invalid JSON - Please paste the valid JSON");
    }

    if (formattedData) {
      let formatData = JSON.stringify(JSON.parse(formattedData), null, 2);
      setFormattedData(formatData);
      setHasStringified(false);
    }
  };

  const minifyJSON = () => {
    if (!isValidJSON) {
      alert("Invalid JSON - Please paste the valid JSON");
    }
    if (!hasStringified) {
      if (formattedData) {
        let formatData = JSON.stringify(JSON.parse(formattedData));
        setFormattedData(formatData);
      }
      setHasStringified(false);
    } else {
      if (formattedData) {
        let formatData = formattedData.replace(/\\/g, "");
        formatData = formatData.substring(1, formatData.length - 1);
        setFormattedData(formatData);
      }
      setHasStringified(false);
    }
  };

  const stringifyJSON = () => {
    if (!isValidJSON) {
      alert("Invalid JSON - Please paste the valid JSON");
    }
    if (!hasStringified) {
      if (formattedData) {
        let formatData = JSON.stringify(JSON.parse(formattedData));
        formatData = formatData
          .replace(/\\/g, "\\\\")
          .replace(/\u0008/g, "\\b")
          .replace(/\t/g, "\\t")
          .replace(/\n/g, "\\n")
          .replace(/\f/g, "\\f")
          .replace(/\r/g, "\\r")
          .replace(/'/g, "\\'")
          .replace(/"/g, '\\"');
        setFormattedData(`"${formatData}"`);
      }
      setHasStringified(true);
    }
  };

  const clearContent = () => {
    // document.getElementById("jsondata").value = null;
    // document.getElementById("jsondata"
    //   ) as HTMLDivElement | null;
    // setData(null);
    // setFormattedData(null);
    // setHasStringified(false);
  };

  const storeData = (e: any) => {
    let value = e.target.value;
    if (value && isJSON(value)) {
      setIsValidJSON(true);
      setFormattedData(value);
    } else {
      setFormattedData("");
      setHasStringified(false);
      setIsValidJSON(false);
    }
  };

  const copyData = () => {
    if (formattedData) {
      if (formattedData) {
        navigator.clipboard.writeText(formattedData);
      }
      setCopyLegend("Copied!");
      setCopyColor("bg-green-500 hover:bg-green-700");
      setTimeout(() => {
        setCopyLegend("Copy");
        setCopyColor("bg-blue-500 hover:bg-blue-700");
      }, 3000);
    }
  };

  const isJSON = (str: any) => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  };

  const convertUiNode = async () => {
    try {
      setError([]);
      let tempNode: any = JSON.parse(formattedData || "{}");

      const getJsonData: any = await convertIntoUiJson(tempNode?.nodes);

      setUiNodes1(getJsonData || []);
    } catch (error) {
      setError(["invalidJson"]);
    }
  };

  useEffect(() => {
    convertUiNode();
  }, [formattedData]);

  const convertIntoUiJson = (jsonData: any) => {
    try {
      let orderCounts: any = [];
      return (
        jsonData &&
        jsonData.length > 0 &&
        jsonData.map((val: any) => {
          let childList = jsonData
            .filter((row: any) => val.id === row.parentNode)
            .map((row: any) => row.id);

          let order = orderCounts.filter((row: any) => val.parentNode === row);
          orderCounts.push(val.parentNode);

          return {
            id: val.id,
            // nodeTitle: val.data.label,
            order: order.length + 1,
            children: childList,
            // data: val.data,
          };
        })
      );
    } catch (error) {
      alert("json is not correct.");
    }
  };

  const generateForm = async () => {
    let err: any = {};

    let data = JSON.parse(formattedData || "{}");
    if (!data.nodes || !data.edges || !data.viewport) {
      err.keyMissing = "JSON must have Nodes, edges and viewport keys exist.";
    }
    if (!(Object.keys(err).length === 0)) {
      setErrors(err);
    } else {
      setErrors({});
      if (Object.keys(formState).length > 0) {
        let generateData = {
          title: formState?.flow?.title,
          flowJson: formattedData,
          uiJson: JSON.stringify(uiNodes1),
        };
        await dispatch(createForm(generateData)).then((val: any) => {
          setFormState({
            flow: "",
            version: "",
          });
          dispatch(getAllForms());
          dispatch(getVerifyAuth());
        });
      }
    }
  };

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Typography
            variant="h5"
            component="h5"
            textAlign={"center"}
            marginBottom={2}
            className="form-visualizer-title"
          >
            Form Visualizer JSON
          </Typography>
          <Paper
            elevation={3}
            style={{
              // height: "calc(100vh - 210px)",
              // overflow: "auto",
              width: "100% !important",
            }}
          >
            <div className="json-box-con">
              <JSONEditor
                stringifyJSON={stringifyJSON}
                minifyJSON={minifyJSON}
                beautifyJSON={beautifyJSON}
                clearContent={clearContent}
                copyData={copyData}
                formattedData={formattedData}
                setFormattedData={setFormattedData}
                setData={setData}
                data={data}
                copyLegend={copyLegend}
                setCopyLegend={setCopyLegend}
                copyColor={copyColor}
                setCopyColor={setCopyColor}
                hasStringified={hasStringified}
                setHasStringified={setHasStringified}
                isValidJSON={isValidJSON}
                setIsValidJSON={setIsValidJSON}
                storeData={storeData}
                error={error}
              />
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography
            variant="h5"
            component="h5"
            textAlign={"center"}
            marginBottom={2}
            className="form-visualizer-title"
          >
            UI Generator JSON
          </Typography>
          <Paper
            elevation={3}
            style={{
              height: "calc(100% - 39.5px)",
              width: "100% !important",
            }}
          >
            <ReactJson
              src={uiNodes1}
              enableClipboard={false}
              displayDataTypes={false}
              displayObjectSize={false}
            />
          </Paper>
        </Grid>
      </Grid>
      <div style={{ color: "red", marginTop: 3 }}>
        {errors?.nodeProperties}
        <br />
        {errors?.keyMissing}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          marginTop: 16,
        }}
      >
        <Button
          variant="contained"
          onClick={generateForm}
          sx={{ mr: 2, width: "10%" }}
          disabled={error && error.includes("invalidJson")}
        >
          Save
        </Button>
        <Button
          style={{ background: "#ccc", color: "#000", width: "10%" }}
          variant="contained"
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default JsonPreview;
