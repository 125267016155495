import React from "react";
import { CircularProgress } from "@mui/material";
import SideLogo from "../../assets/images/logo-white.png";

const BackDropLoader = () => {
  return (
    <div className="custom-backdrop">
      <img src={SideLogo} className="mb-3" />
      <CircularProgress color="inherit" />
      <span className="mt-3 text-white">Getting things ready...</span>
    </div>
  );
};

export default BackDropLoader;
