import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { Box, Button, Container, Grid, Paper, Typography } from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import { useAppSelector } from "../../store/store";
import { getProgressData } from "../../store/thunk/documentThunk";
import { addUserForm } from "../../store/thunk/formThunk";
import { anonymousUser } from "../../helper/service";
import PayAndDownloadButton from "../payAndDownload/payAndDownloadButton";
import SignInSignUpButton from "../payAndDownload/signInSignUpButton";
import BackDropLoader from "../../components/loader/backDropLoader";

export default function Home() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { getSignleFormData, uiFormPayload } = useAppSelector(
    (state: any) => state?.formReducer
  );
  const { progressData } = useAppSelector(
    (state: any) => state?.documentReducer
  );

  const { loading } = useAppSelector((state: any) => state.notificationReducer);
  const { uiFormPayloadLoading } = useAppSelector(
    (state: any) => state?.notificationReducer
  );

  const [allDatas, setAllDatas] = React.useState<any>({});
  const [sectionLists, setSectionLists] = React.useState<any>([]);
  const [locked, setLocked] = useState<any>(false);
  const [paymentLoader, setPaymentLoader] = useState<boolean>(false);

  useEffect(() => {
    if (uiFormPayload?.ui_form_lock_state) {
      setLocked(uiFormPayload?.ui_form_lock_state);
      localStorage.setItem("lockedApp", uiFormPayload?.ui_form_lock_state);
    } else {
      setLocked(false);
      localStorage.setItem("lockedApp", "false");
    }
  }, [uiFormPayload]);

  useEffect(() => {
    if (getSignleFormData?.flowJson) {
      const fetchData = async () => {
        const nodeData = JSON.parse(getSignleFormData?.flowJson);

        let getJsonData: any = await convertIntoUiJson(nodeData?.nodes);

        const mergedObj = nodeData?.nodes.flatMap((flowJsonData: any) =>
          getJsonData
            ?.filter(
              (getJsonDataItem: any) => flowJsonData.id === getJsonDataItem.id
            )
            .map((matchingItem: any) => ({ ...matchingItem, ...flowJsonData }))
        );
        setAllDatas(mergedObj);
        // setAllDatas(getJsonData);

        const sectionData = nodeData?.nodes?.filter(
          (obj: any) => obj?.data?.nodeType === "section"
        );

        setSectionLists(sectionData);
      };
      fetchData();
    }
  }, [getSignleFormData]);

  useEffect(() => {
    dispatch(getProgressData());
  }, []);

  useEffect(() => {
    const isEligibilityFilled = progressData?.find(
      (section: any) => section?.sectionKey === "General_Eligibility"
    );
    if (isEligibilityFilled && isEligibilityFilled?.percentComplete === 0) {
      navigate("/eligibility-check/Eligibility%20Quiz");
    }
  }, [progressData]);

  const convertIntoUiJson = (jsonData: any) => {
    try {
      let orderCounts: any = [];
      return (
        !!jsonData &&
        jsonData.length > 0 &&
        jsonData.map((val: any) => {
          let childList = jsonData
            .filter((row: any) => val.id === row.parentNode)
            .map((row: any) => row.id);
          let order = orderCounts.filter((row: any) => val.parentNode === row);
          orderCounts.push(val.parentNode);

          return {
            id: val.id,
            // nodeTitle: val?.data?.nodeTitle ? val?.data?.nodeTitle : "",
            parentNode: val?.parentNode,
            order: (!!order?.length ? order?.length : 0) + 1,
            children: childList,
            // data: val?.data,
          };
        })
      );
    } catch (error) {
      alert("json is not correct.");
    }
  };

  const onStart = () => {
    const section = sectionLists.filter(
      (val: any) => val?.id == "General_Eligibility"
    );
    console.log("section--->>>", section);

    navigate(`/eligibility-check/${encodeURI(section[0]?.data?.nodeTitle)}`);
  };

  const onResumeClick = () => {
    const eligibilityProgress = progressData.filter(
      (val: any) =>
        val.sectionKey == "General_Eligibility" && val.percentComplete >= 88
    );

    if (eligibilityProgress.length > 0) {
      const sectionPercentag = sectionLists
        .filter((val: any) => val?.id !== "General_Eligibility")
        .map((section: any) => {
          const matchingItem2 = progressData.find(
            (progress: any) => progress.sectionKey === section.id
          );
          return {
            ...section,
            percentComplete: matchingItem2?.percentComplete || 0,
            missingFlowIds: matchingItem2?.missingFlowIds || [],
            filledFlowIds: matchingItem2?.filledFlowIds || [],
          };
        });

      const filteredSection = sectionPercentag.filter(
        (val: any) => val?.percentComplete < 100
      );

      const activeSectionId = filteredSection[0].id;
      const activeSectionSummary = allDatas?.filter(
        (obj: any) => obj?.parentNode === activeSectionId
      );

      const activeSummaryIndex = activeSectionSummary.findIndex(
        (val: any) => val?.data?.nodeTitle == "Summary"
      );

      if (filteredSection[0]?.id !== "General_Eligibility") {
        navigate(`/eligibility-check/${filteredSection[0]?.data?.nodeTitle}`);
      } else {
        navigate(
          `/eligibility-check/${filteredSection[0]?.data?.nodeTitle}?summary=${activeSectionSummary[activeSummaryIndex]?.id}`
        );
      }
    } else {
      onStart();
    }
  };

  const handleLocking = async (flag = false) => {
    const tempUnSavedFormValues: any =
      sessionStorage.getItem("unSavedFormValues");
    const tempUpdatedFormValues: any =
      sessionStorage.getItem("updatedFormValues");

    let updatedFormValues = JSON.parse(tempUpdatedFormValues);
    let unSavedFormValues = JSON.parse(tempUnSavedFormValues);

    updatedFormValues = {
      ...updatedFormValues,
      ["ui_form_lock_state"]: flag,
    };

    await dispatch(
      addUserForm({
        form_data: JSON.stringify(updatedFormValues),
      })
    ).then((res: any) => {
      if (res?.payload?.data) {
        sessionStorage.setItem(
          "unSavedFormValues",
          JSON.stringify({ ...unSavedFormValues, ["ui_form_lock_state"]: flag })
        );
        localStorage.setItem("lockedApp", JSON.stringify(flag));
        setLocked(flag);
        window.location.reload();
      }
    });
  };

  const getActionButton = () => {
    if (
      progressData &&
      progressData?.length &&
      sectionLists &&
      sectionLists?.length &&
      !anonymousUser()
    ) {
      if (
        progressData?.filter(
          (obj: any) =>
            obj?.percentComplete == 0 &&
            obj?.sectionKey == "General_Eligibility"
        )?.length
      ) {
        return (
          <Button
            variant="contained"
            sx={{
              background: "#3260e4",
              boxShadow: "none",
              "&:hover": {
                background: "#3260e4",
              },
            }}
            onClick={onStart}
            disabled={paymentLoader}
          >
            Start
          </Button>
        );
      } else if (
        progressData?.filter(
          (obj: any) =>
            obj?.percentComplete > 0 && obj?.sectionKey == "General_Eligibility"
        )?.length
      ) {
        return (
          <Button
            variant="contained"
            sx={{
              background: "#2563eb",
              boxShadow: "none",
              "&:hover": {
                background: "#3260e4",
              },
            }}
            onClick={onResumeClick}
            disabled={paymentLoader}
          >
            Resume
          </Button>
        );
      } else if (
        progressData &&
        sectionLists &&
        progressData?.filter((obj: any) => obj?.percentComplete >= 100)
          ?.length == sectionLists?.length
      ) {
        return (
          <Typography
            sx={{
              display: "flex",
              alignItems: "center",
              textAlign: "center",
              fontSize: "14px",
              lineHeight: "20px",
              color: "#2dac5d",
            }}
            variant="caption"
            component="div"
            fontFamily="Sen"
          >
            Completed
          </Typography>
        );
      }
    } else if (anonymousUser()) {
      return (
        <Button
          variant="contained"
          sx={{
            background: "#3260e4",
            boxShadow: "none",
            "&:hover": {
              background: "#3260e4",
            },
          }}
          onClick={onStart}
          disabled={paymentLoader}
        >
          Start
        </Button>
      );
    } else {
      return null;
    }
  };

  return (
    <>
      {(loading || uiFormPayloadLoading) && !anonymousUser() && (
        <BackDropLoader />
      )}
      <Container maxWidth="lg">
        <Box
          className="custom-scrollbar payment"
          height={"calc(100vh - 133px)"}
          overflow={"auto"}
          style={{ paddingBottom: "100px" }}
        >
          <Paper
            className="homepage"
            sx={{
              border: "1px solid #ddd",
              borderRadius: "20px",
              boxShadow: "none",
              padding: { md: "20px", xs: "10px" },
            }}
          >
            <h1>Applications</h1>
            <Box className="home-application-title md:flex block justify-between">
              <Typography variant="h4">Marriage based AOS</Typography>
              <Box sx={{ display: "flex", mt: { md: 0, xs: 1 } }}>
                {getActionButton()}

                {!anonymousUser() && (
                  <>
                    <Button
                      variant="outlined"
                      sx={
                        !locked
                          ? { background: "#eff6ff" }
                          : { color: "#9ebaf6 !important" }
                      }
                      onClick={() => handleLocking(true)}
                      disabled={
                        !locked &&
                        !paymentLoader &&
                        sessionStorage.getItem("Usapath-Emulate-UserScope") !==
                          "Readonly"
                          ? false
                          : true
                      }
                    >
                      <LockOutlinedIcon />
                      Lock
                    </Button>
                    <Button
                      variant="outlined"
                      disabled={locked ? false : true}
                      sx={
                        locked
                          ? { background: "#eff6ff" }
                          : { color: "#9ebaf6 !important" }
                      }
                      onClick={() => handleLocking(false)}
                    >
                      <LockOpenOutlinedIcon />
                      Unlock
                    </Button>
                  </>
                )}
              </Box>
            </Box>
          </Paper>
          <Box>
            <Grid container spacing={2}>
              {!anonymousUser() && (
                <Grid item xs={12} md={6}>
                  <Paper
                    sx={{
                      padding: { md: "20px", xs: "10px" },
                      border: "1px solid #ddd",
                      borderRadius: "20px",
                      boxShadow: "none",
                    }}
                  >
                    <h1 style={{ fontWeight: "600", marginBottom: "10px" }}>
                      Billing
                    </h1>

                    <Box sx={{ margin: "10px 0 0" }}>
                      <Button
                        variant="contained"
                        sx={{
                          borderRadius: "20px",
                          mr: 1,
                          textTransform: "capitalize",
                          background: "#2563eb",
                          boxShadow: "none",
                        }}
                        disabled={paymentLoader}
                      >
                        View Invoice
                      </Button>
                    </Box>
                  </Paper>
                </Grid>
              )}
              <Grid item xs={12} md={anonymousUser() ? 12 : 6}>
                <Paper
                  sx={{
                    padding: { md: "20px", xs: "10px" },
                    border: "1px solid #ddd",
                    borderRadius: "20px",
                    boxShadow: "none",
                    overflowY: "auto",
                    mb: "100px",
                  }}
                >
                  {anonymousUser() ? (
                    <>
                      <Box
                        sx={{
                          background: "#eff6fe",
                          borderRadius: "12px",
                          m: "0 auto",
                        }}
                        display={"flex"}
                        flexDirection={"column"}
                        justifyContent={"center"}
                        alignItems={"center"}
                      >
                        <SignInSignUpButton source={"home"} onStart={onStart} />
                      </Box>
                    </>
                  ) : (
                    <>
                      <h1 style={{ fontWeight: "600", marginBottom: "10px" }}>
                        Pay and Download
                      </h1>
                      <PayAndDownloadButton isBoth={true} />
                    </>
                  )}
                </Paper>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </>
  );
}
