import { useState } from "react";
import {
  TextField,
  Box,
  Select,
  FormControl,
  MenuItem,
  Button,
  IconButton,
  DialogTitle,
  Dialog,
} from "@mui/material";
import "./index.css";

const FormVersion = (props: any) => {
  let {
    formState,
    formInputChange,
    formNameList,
    formVersionList,
    addNewForm,
  } = props;
  const [isDialog, setIsDialog] = useState(false);
  const [formName, setFormName] = useState("");

  const handleClose = () => {
    setIsDialog(false);
  };
  const handleOpen = () => {
    setIsDialog(true);
  };

  const handleFormName = (event: any) => {
    let formName = event.target.value;
    setFormName(formName);
  };

  const addName = () => {
    addNewForm(formName);
    handleClose();
  };

  return (
    <div className="flex items-start mb-[16px] overflow-auto">
      <div style={{ display: "flex", alignItems: "center", marginRight: 0 }}>
        <label style={{ fontWeight: '700' }}>Form Name</label>
        <FormControl sx={{ m: 1, minWidth: 250 }} size="small">
          <Select
            labelId="demo-select-small-label1"
            id="demo-select-small1"
            value={formState?.flow || ""}
            label="name"
            name="flow"
            onChange={formInputChange}
          >
            {formNameList &&
              formNameList.length > 0 &&
              formNameList.map((list: any, index: any) => {
                return (
                  <MenuItem key={index} value={list}>
                    {list.title}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginRight: 24,
          alignSelf: "center",
        }}
        className="form-add-btn"
      >
        <IconButton
          style={{
            background: "#ccc",
            height: "31px",
            width: "31px",
          }}
          aria-label="delete"
          onClick={handleOpen}
        >
          +
        </IconButton>
      </div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <label style={{ fontWeight: '700' }}>Form Version</label>
        <FormControl sx={{ m: 1, minWidth: 150 }} size="small">
          <Select
            labelId="demo-select-small-label"
            id="demo-select-small1"
            value={formState?.version || 1}
            label="Version"
            name="version"
            onChange={formInputChange}
          >
            {formVersionList &&
              formVersionList?.map((vList: any, index: any) => {
                return <MenuItem value={vList}>{vList.version}</MenuItem>;
              })}
          </Select>
        </FormControl>
      </div>

      <Dialog onClose={handleClose} open={isDialog}>
        <DialogTitle>Create form name</DialogTitle>
        <Box
          component="form"
          style={{ padding: "0 20px", width: "350px" }}
          sx={{
            "& > :not(style)": { m: 1, width: "300px" },
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            id="outlined-basic"
            label="Name"
            variant="outlined"
            name="formName"
            onChange={handleFormName}
          />
          <Button variant="contained" onClick={addName}>
            Add
          </Button>
        </Box>
      </Dialog>
    </div>
  );
};

export default FormVersion;
