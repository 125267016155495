import { useEffect } from "react";
import TableComponent from "../../components/table/sampleTable";
import { useAppDispatch } from "../../store/store";
import { getUserList } from "../../store/thunk/userThunk";

const UserTable = () => {
  const dispatch: any = useAppDispatch();

  useEffect(() => {
    dispatch(getUserList());
  }, []);

  return (
    <div className="md:p-8 p-4">
      <TableComponent />
    </div>
  );
};

export default UserTable;
