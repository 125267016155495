import { Button } from "@material-tailwind/react";
import YesIcon from "../../icon/YesIcon";

function StepperComponent(props: any) {
  const {
    handleFieldChange,
    title,
    editable,
    id,
    value,
    errors,
    filedProp,
    style,
  } = props;

  const btnClassName =
    style.btnClass === "check_btn"
      ? "h-max mx-0 text-center text-sm shadow-none bg-blue-50 text-black rounded-[12px] text-blue-600 normal-case "
      : "mx-0 text-[16px] text-black text-center rounded-[12px] px-[22px] bg-[#F9FAFB] block my-[4px] capitalize shadow-none  border border-solid border-[#E5E7EB]";

  const handleChange = (val: any) => {
    if (editable) {
      if (value == val) {
        handleFieldChange(id, "");
      } else {
        handleFieldChange(id, val);
      }
    }
  };

  const getBgColor = (isSelect: any) => {
    if (!editable && !isSelect) {
      return `bg-[rgba(0,0,0,0.05)]`;
    } else if (!editable && isSelect) {
      return `bg-[rgba(0,0,0,0.3)]`;
    } else if (editable && isSelect) {
      return `bg-[#2563EB] text-white`;
    }
  };
  return (
    <div
      className={`${
        style.direction?.toLowerCase() == "row" ? "w-[85%]" : "max-w-[300px]"
      } w-full`}
    >
      <div
        className={`w-full rounded-[64px] flex rounded-[15px] shadow-none flex  
        ${
          style?.direction?.toLowerCase() === "row"
            ? "flex-row justify-between items-center"
            : "flex-col justify-center"
        }`}
      >
        <div className="text-start">
          <label
            className="input-label block ml-1"
            dangerouslySetInnerHTML={{ __html: title }}
            style={{
              fontSize: "14px",
              fontWeight: 400,
              color: "#6B7280",
              ...(style.direction?.toLowerCase() == "row" && {
                paddingRight: "4rem",
              }),
            }}
          ></label>
        </div>
        {Object.keys(filedProp?.properties || {}).length > 0 &&
        filedProp?.properties?.values?.length > 0 ? (
          <div
            className={`flex ${
              style?.direction?.toLowerCase() == "row"
                ? "flex-row "
                : "flex-col"
            }`}
          >
            {filedProp?.properties?.values?.map((item: any) => (
              <Button
                loading={false}
                size="md"
                type="button"
                onPointerEnterCapture={() => {}}
                onPointerLeaveCapture={() => {}}
                placeholder="Test"
                id={item?.key}
                onClick={() => {
                  handleChange(item?.value);
                }}
                className={`min-h-[38px] ${btnClassName} ${getBgColor(
                  value === item?.value
                )} text-base text-[13px] w-[-webkit-fill-available] px-6 py-0 mb-[7px] ${
                  style?.direction?.toLowerCase() == "row" && "mr-[12px]"
                }`}
                style={{ fontFamily: "Sen" }}
              >
                {value === item?.value && style.btnClass === "check_btn" ? (
                  <div className="flex">
                    <div className="mr-2">
                      <YesIcon />
                    </div>{" "}
                    {item?.key}
                  </div>
                ) : (
                  item?.key
                )}
              </Button>
            ))}
          </div>
        ) : (
          <>
            <Button
              loading={false}
              size="md"
              type="button"
              onPointerEnterCapture={() => {}}
              onPointerLeaveCapture={() => {}}
              id="boolean_yes"
              placeholder="Test"
              onClick={() => {
                handleChange("yes");
              }}
              className={`min-h-[38px] min-w-[250px] mb-[20px] text-black text-center rounded-[12px] px-[48px] bg-[#F9FAFB] block my-[10px] capitalize shadow-none border border-solid border-[#E5E7EB] mx-0 mb-[7px] text-[13px] ${
                value === "yes" ? "bg-[#ddddff]" : ""
              } `}
              style={{ fontFamily: "Sen" }}
            >
              Yes
            </Button>
            <Button
              loading={false}
              size="md"
              type="button"
              onPointerEnterCapture={() => {}}
              onPointerLeaveCapture={() => {}}
              id="boolean_no"
              placeholder="Test"
              onClick={() => {
                handleChange("no");
              }}
              style={{ fontFamily: "Sen" }}
              className={`min-h-[38px] min-w-[250px] mb-[20px] text-black text-center rounded-[12px] px-[48px] bg-[#F9FAFB] block my-[10px] capitalize shadow-none border border-solid border-[#E5E7EB] mx-0 mb-[7px] text-[13px] ${
                value === "no" ? "bg-[#ddddff]" : ""
              } `}
            >
              No
            </Button>
          </>
        )}
      </div>
      {errors && errors[id] && (
        <p style={{ color: "red", textAlign: "center" }}>{errors[id]}</p>
      )}
    </div>
  );
}
export default StepperComponent;
