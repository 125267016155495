import FormFlow from "../../components/formFlow";

const Formflow = () => {
  return (
    <div className="relative overflow-x-auto h-[calc(100vh_-_130px)] md:h-[calc(100vh_-_211px)] p-2">
      <FormFlow />
    </div>
  );
};

export default Formflow;
