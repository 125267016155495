interface IncomeValues {
  [key: string]: string;
}

interface PersonIncome {
  [key: string]: number;
}

interface IncomeLimitItem {
  ilId: number;
  householdSize: number;
  state: string;
  income: number;
}

interface OutputValues {
  [key: string]: string;
}

const householdSizeTotalApplicantKey = "FINF812d";
const dependentChildrenApplicantKey = "FINF811a";
const otherDependentApplicantKey = "FINF812b";
const otherHouseholdMemberApplicantKey = "FINF812e";

const sponsorIncome814Key = "FINF814";
const sponsorAsset815Key = "FINF815";
const sponsorAsset816Key = "FINF816";
const sponsorAsset817Key = "FINF817";

const immigrantIncome825Key = "FINF825";
const immigrantAsset826Key = "FINF826";
const immigrantAsset827Key = "FINF827";
const immigrantAsset828Key = "FINF828";

const totalIncomeKey = "FINF828a";
const sponsorTotalAssetKey = "FINF828d";
const immigrantTotalAssetKey = "FINF828e";
const totalAssetKey = "FINF828f";

const finf811Key = "FINF811";
const finf812Key = "FINF812";
const it381Key = "IT381";
const it382Key = "IT382";
const it382aKey = "IT382a";

const stateKey = "PI351";

const incomeLimitFlagKey = "income_limit_flag";
const householdSizeKey = "FINF824b";

const expectedInputKeys = [
  sponsorIncome814Key /* FINF814 */,
  sponsorAsset815Key /* FINF815 */,
  sponsorAsset816Key /* FINF816 */,
  sponsorAsset817Key /* FINF817 */,
  immigrantIncome825Key /* FINF825 */,
  immigrantAsset826Key /* FINF826 */,
  immigrantAsset827Key /* FINF827 */,
  immigrantAsset828Key /* FINF828 */,
  finf811Key /* FINF811 */,
  finf812Key /* FINF812 */,
  it381Key /* IT381 */,
  it382Key /* IT382 */,
  it382aKey /* IT382a */,
  stateKey /* PI351 */,
  dependentChildrenApplicantKey /* FINF811a */,
  otherDependentApplicantKey /* FINF812b */,
  otherHouseholdMemberApplicantKey /* FINF812e */,
];

function calculateIncomeFlag(
  incomeLimits: IncomeLimitItem[],
  incomeValues: IncomeValues
): OutputValues {
  log(`Received input - ${JSON.stringify(incomeValues)}`);
  // Verify that each expected key exists in incomeValues
  const allKeysExist = expectedInputKeys.every((key) => key in incomeValues);

  if (!allKeysExist) {
    console.log("Not all expectedKeys exist in incomeValues");
    return {};
    // throw new Error("Not all expectedKeys exist in incomeValues");
  }

  const sponsorIncome: number = getNumber(sponsorIncome814Key, incomeValues);
  const immigrantIncome: number = getNumber(
    immigrantIncome825Key,
    incomeValues
  );
  const totalIncome: number = sponsorIncome + immigrantIncome;

  const sponsorAsset1: number = getNumber(sponsorAsset815Key, incomeValues);
  const sponsorAsset2: number = getNumber(sponsorAsset816Key, incomeValues);
  const sponsorAsset3: number = getNumber(sponsorAsset817Key, incomeValues);
  const sponsorTotalAsset: number =
    sponsorAsset1 + sponsorAsset2 + sponsorAsset3;

  const immigrantAsset1: number = getNumber(immigrantAsset826Key, incomeValues);
  const immigrantAsset2: number = getNumber(immigrantAsset827Key, incomeValues);
  const immigrantAsset3: number = getNumber(immigrantAsset828Key, incomeValues);
  const immigrantTotalAsset: number =
    immigrantAsset1 + immigrantAsset2 + immigrantAsset3;

  const totalAsset: number = sponsorTotalAsset + immigrantTotalAsset;

  const it382: number = getIT382Value(incomeValues);
  const finf811: number = getNumber(finf811Key, incomeValues);
  const finf812: number = getNumber(finf812Key, incomeValues);

  const householdSize: number = 2 + finf811 + finf812 + it382;

  const state: string = getStateValue(incomeValues);

  let targetHouseholdSize = householdSize;
  let additionalIncomeLimit = 0;

  if (householdSize > 8) {
    const addPersonIncome: PersonIncome = {
      Contiguous: 6425,
      AK: 6425,
      HI: 7387,
    };

    targetHouseholdSize = 8;
    const addPersons = householdSize - targetHouseholdSize;
    additionalIncomeLimit = addPersons * addPersonIncome[state];
  }

  const matches: IncomeLimitItem[] = incomeLimits.filter(
    (item) => item.state === state && item.householdSize === targetHouseholdSize
  );
  const incomeLimit: number = matches.length > 0 ? matches[0].income : 0;
  const shortfall = incomeLimit + additionalIncomeLimit - totalIncome;

  var flag = 0;
  if (sponsorIncome >= incomeLimit + additionalIncomeLimit) {
    flag = 1;
  } else if (totalIncome >= incomeLimit + additionalIncomeLimit) {
    flag = 2;
  } else if (sponsorTotalAsset >= shortfall * 3) {
    flag = 3;
  } else if (totalAsset >= shortfall * 3) {
    flag = 4;
  }

  const dependentChildrenApplicant = getNumber(
    dependentChildrenApplicantKey,
    incomeValues
  );
  const otherDependentApplicant = getNumber(
    otherDependentApplicantKey,
    incomeValues
  );
  const otherHouseholdMemberApplicant = getNumber(
    otherHouseholdMemberApplicantKey,
    incomeValues
  );
  const householdSizeTotalApplicant =
    2 +
    dependentChildrenApplicant +
    otherDependentApplicant +
    otherHouseholdMemberApplicant;

  const outputValues: OutputValues = {};
  outputValues[incomeLimitFlagKey] = flag.toString();
  outputValues[totalIncomeKey] = totalIncome.toString();
  outputValues[sponsorTotalAssetKey] = sponsorTotalAsset.toString();
  outputValues[immigrantTotalAssetKey] = immigrantTotalAsset.toString();
  outputValues[totalAssetKey] = totalAsset.toString();
  outputValues[householdSizeKey] = householdSize.toString();
  outputValues[householdSizeTotalApplicantKey] =
    householdSizeTotalApplicant.toString();

  log(`Output - ${JSON.stringify(outputValues)}`);
  return outputValues;
}

function getNumber(key: string, incomeValues: IncomeValues) {
  let value: number = incomeValues[key] // FINF815 + FINF816 + FINF817
    ? +incomeValues[key]
    : 0;

  if (isNaN(value) || value < 0) {
    error(
      `${key} must be greater than or equal to 0. Received - '${incomeValues[key]}'`
    );
    value = 0;
  }

  return value;
}

function getIT382Value(incomeValues: IncomeValues) {
  const value =
    incomeValues[it381Key] === "Y" &&
    incomeValues[it382aKey] === "Y" &&
    incomeValues[it382Key]
      ? getNumber(it382Key, incomeValues)
      : 0;

  return value;
}

function getStateValue(incomeValues: IncomeValues) {
  let state: string = incomeValues[stateKey] ? incomeValues[stateKey] : "";

  if (state !== "AK" && state !== "HI") {
    state = "Contiguous";
  }

  return state;
}

function log(message: string) {
  // console.log("calculateIncomeFlag", message);
}

function error(message: string) {
  // console.error("calculateIncomeFlag - " + message);
}

// You might still want to export the function in case you'll use it elsewhere.
export { calculateIncomeFlag };
