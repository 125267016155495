function DropdownField(props: any) {
  const { options, title, editable, id, handleFieldChange, value, errors } =
    props;

  const handleChange = (event: any) => {
    if (value == event.target.value) {
      handleFieldChange(id, "");
    } else {
      handleFieldChange(id, event.target.value);
    }
  };

  return (
    <div className="flex flex-col w-full" style={{ maxWidth: "300px" }} id={id}>
      <div className="text-start">
        <label
          className="input-label block ml-1"
          dangerouslySetInnerHTML={{ __html: title }}
          style={{
            fontSize: "14px",
            fontWeight: 400,
            color: "#6B7280",
          }}
        ></label>
      </div>

      <div className="select">
        <select
          name="format"
          id="format"
          className={id}
          disabled={!editable}
          value={value}
          onChange={handleChange}
          style={{
            ...(!editable && {
              background: "rgba(0,0,0,0.05)",
              color: "rgba(0,0,0,0.38)",
              opacity: 1,
            }),
          }}
        >
          <option selected value={""}>
            {""}
          </option>
          {options.map((option: any) => (
            <option selected value={option.value}>
              {option.key}
            </option>
          ))}
        </select>
      </div>
      {errors && errors[id] && (
        <p style={{ color: "red" }} className="absolute left-0">
          {errors[id]}
        </p>
      )}
    </div>
  );
}

export default DropdownField;
