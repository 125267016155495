import { useEffect, useState } from "react";
import { Box, Card, Typography, CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import TaskIcon from "../../assets/images/FormIcons/Task.svg";
import { useDispatch } from "react-redux";
import {
  getVerifyAuth,
  getVerifyEmail,
  resendVerifyEmailLink,
} from "../../store/thunk/authThunk";

const Verify = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isUserVerified, setIsUserVerified] = useState<any>(false);

  let userData: any = JSON.parse(localStorage.getItem("userData") || "{}");
  let accessToken = localStorage.getItem("accessToken");

  useEffect(() => {
    if (userData && userData?.email_verified) {
      navigate("/home");
    }
  }, [userData, isUserVerified]);

  useEffect(() => {
    if (isUserVerified) return;
    const verifyInterval = setInterval(() => {
      dispatch(getVerifyEmail()).then((res: any) => {
        if (res?.payload?.data === "verified") {
          
          dispatch(getVerifyAuth(accessToken)).then((res: any) => {
            if (res?.payload?.status == 200 && res?.payload?.data?.isVerified) {
              let tempUserData = userData;
              tempUserData["email_verified"] = true;
              localStorage.setItem("userData", JSON.stringify(tempUserData));
              setIsUserVerified(true);
            }
          });

        }
      });
    }, 3000);
    return () => clearInterval(verifyInterval);
  }, []);

  const resendMail = () => {
    dispatch(resendVerifyEmailLink());
  };

  return (
    <Card
      sx={{
        height: "calc(100vh - 133px)",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
      }}
    >
      <Box
        sx={{
          height: "85%",
          width: "80%",
          bgcolor: "rgba(239, 246, 255, 1)",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          borderRadius: 2,
        }}
      >
        <Box component="img" src={TaskIcon} height={100} sx={{ mb: 2 }} />
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          Email Verification Required
        </Typography>
        <Typography variant="subtitle1" sx={{ mb: 4, textAlign: "center" }}>
          Please check your inbox for verification email. Waiting for email to be verified <CircularProgress size={16} />
        </Typography>
        <Typography variant="subtitle1" sx={{ mb: 4, textAlign: "center" }}>
          Not received your verification email yet?{" "}
          <span
            style={{ color: "#0d18a0", cursor: "pointer", fontWeight: "bold" }}
            onClick={resendMail}
          >
            click here
          </span>{" "}
          to resend the verification email.
        </Typography>
        <Typography variant="subtitle1" sx={{ mb: 4, textAlign: "center" }}>
          Issues? Please reach out to support using the chat button below
        </Typography>
      </Box>
    </Card>
  );
};

export default Verify;
