import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, MenuItem, Select, TextField, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import "reactflow/dist/style.css";
import "./index.css";
import {
  Grid,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { publicUserFormAdd } from "../../../store/thunk/formThunk";
import { useAppSelector } from "../../../store/store";

const FormPreview = (props: any) => {
  const { uiNodes, formState, formId } = props;
  const dispatch: any = useDispatch();

  const { userForm } = useAppSelector((state: any) => state?.formReducer);

  const [fields, setFields] = useState<any>({});
  const [errors, setErrors] = useState<any>({});
  const [isDialog, setIsDialog] = useState(false);

  useEffect(() => {
    let localStorageSavedValue: any = localStorage.getItem("user-form");
    if (localStorageSavedValue) {
      let savedValue: any = JSON.parse(localStorageSavedValue);
      if (savedValue.form_id == formId) {
        if (savedValue && savedValue.form_data) {
          let fieldJson = JSON.parse(savedValue.form_data);
          setFields(fieldJson);
        } else if (uiNodes && uiNodes.length > 0) {
          if (savedValue && savedValue.form_data) {
            let fieldJson = JSON.parse(savedValue.form_data);
            setFields(fieldJson);
          } else {
            let tempState: any = {};
            uiNodes
              .filter(
                (val: any) => val?.data?.type?.toLowerCase() == "question"
              )
              .map((val: any) => {
                tempState[val.id] = "";
              });
            setFields(tempState);
          }
        }
      }
    }
  }, [userForm]);

  useEffect(() => {
    let localStorageSavedValue: any = localStorage.getItem("user-form");
    if (uiNodes && uiNodes.length > 0) {
      let savedValue = JSON.parse(localStorageSavedValue);
      if (savedValue && savedValue.form_id == formId) {
        if (savedValue && savedValue.form_data) {
          let fieldJson = JSON.parse(savedValue.form_data);
          setFields(fieldJson);
        } else {
          let tempState: any = {};
          uiNodes
            .filter((val: any) => val?.data?.type?.toLowerCase() == "question")
            .map((val: any) => {
              tempState[val.id] = "";
            });
          setFields(tempState);
        }
      }
    }
  }, [uiNodes]);

  const handleChange = (event: any) => {
    setFields({
      ...fields,
      [event.target.name]: event.target.value,
    });
  };

  const handleSave = () => {
    let formDataObj = {
      form_id: formState.flow_id,
      form_data: JSON.stringify(fields),
    };
    localStorage.setItem("user-form", JSON.stringify(formDataObj));
  };

  let tempNodes: any = [];

  const renderFields = (field: any) => {
    let type = field?.data?.type;
    let filedProp = field?.data?.properties;
    let validations = filedProp?.properties?.validations;
    let placeholder = filedProp?.properties?.placeholder;
    let fieldTitle = field?.data?.properties?.title;
    if (
      type &&
      filedProp?.type?.toLowerCase() == "textbox" &&
      filedProp &&
      field?.data?.visible == "{true}"
    ) {
      return (
        <>
          <div className="que-container">{field.title}</div>
          <div className="form-field-container">
            <label>{fieldTitle}</label>
            <TextField
              fullWidth
              variant="outlined"
              placeholder={placeholder || ""}
              onChange={handleChange}
              name={field.id}
              value={fields[field.id]}
              onBlur={handleSave}
              helperText={errors[field.id] || ""}
              error={errors[field.id]}
            />
          </div>
        </>
      );
    }
    if (
      filedProp?.type?.toLowerCase() == "dropdown" &&
      filedProp &&
      field?.data?.visible == "{true}"
    ) {
      return (
        <>
          <div className="que-container">{field.title}</div>
          <div className="form-field-container">
            <label>{fieldTitle}</label>
            <Select
              fullWidth
              style={{ minWidth: 200 }}
              onChange={handleChange}
              name={field.id}
              value={fields[field.id] || ""}
              onBlur={handleSave}
              error={errors[field.id]}
            >
              <MenuItem value={"Yes "}>Yes</MenuItem>
              <MenuItem value={"No "}>No</MenuItem>
            </Select>
          </div>
        </>
      );
    }
    if (
      filedProp?.type?.toLowerCase() == "datetime" &&
      filedProp &&
      field?.data?.visible == "{true}"
    ) {
      return (
        <>
          <div className="que-container">{field.title}</div>
          <div className="form-field-container">
            <label>{fieldTitle}</label>
            <TextField
              type="date"
              label={placeholder}
              variant="outlined"
              placeholder={placeholder || ""}
              onChange={handleChange}
              name={field.id}
              value={fields[field.id]}
              onBlur={handleSave}
              helperText={errors[field.id] || ""}
              error={errors[field.id]}
            />
          </div>
        </>
      );
    }
    return field.title;
  };

  const renderUiForms = (myNodes: any) => {
    return myNodes.map((val: any, index: any) => {
      if (!tempNodes.includes(val.id)) {
        tempNodes.push(val.id);
        if (val.children && val.children.length > 0) {
          return (
            <Grid container>
              {val?.data?.type?.toLowerCase() == "section" ? (
                <>
                  <Grid md={12} sx={{ background: "#cac4eb", mt: 4, p: 2 }}>
                    <Typography variant={"h5"}>{val.title}</Typography>
                  </Grid>
                  {renderUiForms(
                    uiNodes.filter((row: any) => val.children.includes(row.id))
                  )}
                </>
              ) : (
                <>
                  <Grid
                    md={12}
                    sx={{ background: "#ebe9f9", mt: 4, p: 2, pl: 4, ml: 3 }}
                  >
                    <Typography variant={"h6"}>{val.title}</Typography>
                  </Grid>
                  {renderUiForms(
                    uiNodes.filter((row: any) => val.children.includes(row.id))
                  )}
                  <Grid md={12} sx={{ p: 2, pt: 4 }}>
                    <Divider />
                  </Grid>
                </>
              )}
            </Grid>
          );
        } else {
          return (
            <Grid key={index} md={4} sx={{ pl: 4, mt: 1 }}>
              {renderFields(val)}
            </Grid>
          );
        }
      }
    });
  };

  const handleSubmit = () => {
    if (uiNodes && uiNodes.length > 0) {
      let questionNodes = uiNodes.filter(
        (val: any) => val?.data?.type?.toLowerCase() == "question"
      );

      let errors: any = {};

      questionNodes.map((row: any) => {
        let rowFormValue = fields[row.id];
        let validations = row.data?.properties?.properties?.validations;
        if (validations && validations.length > 0) {
          validations.map((vld: any) => {
            if (vld.type === "regex") {
              var re = new RegExp(vld.value);
              if (!re.test(rowFormValue)) {
                errors[row.id] = vld.message || "please enter valid value";
              }
            }
            if (vld.type === "length") {
              if (rowFormValue && rowFormValue.length > vld.value) {
                errors[row.id] = vld.message || "please enter valid value";
              }
            }
            if (row.data?.properties.required === "{true}") {
              if (!rowFormValue) {
                errors[row.id] = "please enter valid value";
              }
            }
          });
        }
      });
      setErrors(errors);
      return errors && Object.keys(errors).length == 0 ? true : false;
    }
  };

  const saveDataInDB = () => {
    dispatch(
      publicUserFormAdd({
        form_id: formState.flow_id,
        form_data: JSON.stringify(fields),
        visitorId: localStorage.getItem("visitorId"),
      })
    )
      .then((response: any) => {
        handleClose();
        logIn();
      })
      .catch((err: any) => console.log(err));
  };

  const logIn = () => {
    window.location.href = `${process.env.REACT_APP_TOKEN_ENDPOINT}authorize?response_type=code&client_id=${process.env.REACT_APP_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_REDIRECT_URI}&scope=openid%20email%20profile%20offline_access&audience=https://challenges-api.com`;
  };

  const handleClose = () => {
    setIsDialog(false);
  };
  const handleOpen = () => {
    setIsDialog(true);
  };

  const agreeWithChange = () => {
    saveDataInDB();
  };

  const checkValidation = () => {
    if (handleSubmit()) {
      handleOpen();
    }
  };

  return (
    <>
      <div className="formPreview">
        {renderUiForms(uiNodes)}
        <Button variant="contained" onClick={checkValidation}>
          Preview
        </Button>
        <Dialog
          open={isDialog}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Alert"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure want to save and redirect to login?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>No</Button>
            <Button onClick={agreeWithChange} autoFocus>
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};

export default FormPreview;
