import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  setLoading,
  notificationFail,
  notificationSuccess,
  setDownloadLoading,
} from "../slices/notificationSlice";
import { setSingleUserData, setUsersList } from "../slices/userSlice";
import apiClient from "../../config/apiClient";
import dotnetApiClient from "../../config/dotnetApiClient";
// import { X_TENANT_ID } from '../../common/ActionUrl';
import queryString from "query-string";

export const getUserList = createAsyncThunk(
  "getUserList",
  async (_request, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      // const response = await apiClient().get(`users`);
      const response = await dotnetApiClient().get(`UiUser`);
      dispatch(setLoading(false));
      if (response?.data) {
        dispatch(setUsersList(response?.data));
        dispatch(
          notificationSuccess(response?.data?.message || "User List Loaded")
        );
      } else {
        dispatch(
          notificationFail(response?.data?.message || "Something went wrong")
        );
      }
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(notificationFail("Something went wrong"));
    }
  }
);

// Get Single User API call
export const getSingleUser: any = createAsyncThunk(
  "getSingleUser",
  async (_request: any, { dispatch }) => {
    try {
      const response = await dotnetApiClient().get(`UiUser/${_request.userId}`);
      dispatch(setSingleUserData(response?.data));
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(notificationFail("Something went wrong"));
    }
  }
);

// Get Single User API call
export const deleteSingleUser: any = createAsyncThunk(
  "deleteSingleUser",
  async (_request: any, { dispatch }) => {
    const { sendData } = _request;
    try {
      await dotnetApiClient().put(`UiUser/${sendData?.id}`, sendData, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      dispatch(getUserList());
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(notificationFail("Something went wrong"));
    }
  }
);

// For strip and download related calls
export const getClientSecret: any = createAsyncThunk(
  "getClientSecret",
  async (_request: any, { dispatch }) => {
    try {
      const response = await dotnetApiClient().get(`UiPayment/intent/secret`);
      return response?.data;
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(notificationFail("Something went wrong"));
    }
  }
);

// For strip view

export const getStripeView: any = createAsyncThunk(
  "getStripeView",
  async (_request: any, { dispatch }) => {
    try {
      const response: any = await dotnetApiClient().post(`/UiPayment/checkout`);

      return response;
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(
        notificationFail(
          "We have detected an error. Please contact support for assistance."
        )
      );
    }
  }
);

// For strip payment status
export const getStripePaymentStatus: any = createAsyncThunk(
  "getClientSecret",
  async (_request: any, { dispatch }) => {
    try {
      const response = await dotnetApiClient().get(`/UiPayment/status`);

      return response;
    } catch (error) {
      dispatch(setLoading(false));
      // dispatch(notificationFail("Something went wrong"));
    }
  }
);

export const getDocumentDownload: any = createAsyncThunk(
  "getDocumentDownload",
  async (_request: any, { dispatch }) => {
    try {
      const response = await dotnetApiClient().get(`evidencePacket`, {
        responseType: "arraybuffer",
      });

      if (response.data) {
        var blob = new Blob([response.data], { type: "application/pdf" });
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download =
          "Report_" + new Date().toISOString().replace(/[:.]/g, "_") + ".pdf";
        link.click();
      }
      dispatch(setLoading(false));
      dispatch(setDownloadLoading(false));
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(setDownloadLoading(false));
      dispatch(notificationFail("Please make sure you've completed payment."));
    }
  }
);

export const getUserPDFDownload: any = createAsyncThunk(
  "getUserPDFDownload",
  async (_request: any, { dispatch }) => {
    const { userId, formName } = _request;
    dispatch(setLoading(true));

    try {
      const response = await dotnetApiClient().get(
        `pdfpreview/user/${userId}/form/${formName}`,
        {
          responseType: "arraybuffer",
        }
      );

      if (response.data) {
        var blob = new Blob([response.data], { type: "application/pdf" });
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download =
          "Form_" +
          formName +
          "_" +
          new Date().toISOString().replace(/[:.]/g, "_") +
          ".pdf";
        link.click();
      }
      dispatch(setLoading(false));
      dispatch(setDownloadLoading(false));
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(setDownloadLoading(false));
      dispatch(notificationFail("Please make sure you've completed payment."));
    }
  }
);

export const getEvidencePacketDownload: any = createAsyncThunk(
  "getEvidencePacketDownload",
  async (_request: any, { dispatch }) => {
    const { userId } = _request;
    dispatch(setLoading(true));

    try {
      const response = await dotnetApiClient().get(
        `/evidencepacket/download/user/${userId}`,
        {
          responseType: "arraybuffer",
        }
      );

      if (response.data) {
        var blob = new Blob([response.data], { type: "application/pdf" });
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download =
          "Evidence_Form" +
          "_" +
          new Date().toISOString().replace(/[:.]/g, "_") +
          ".pdf";
        link.click();
      }
      dispatch(setLoading(false));
      dispatch(setDownloadLoading(false));
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(setDownloadLoading(false));
      dispatch(notificationFail("Please make sure you've completed payment."));
    }
  }
);

export const getHistoryChanges: any = createAsyncThunk(
  "replenish",
  async (_request, { dispatch }) => {
    const { userId }: any = _request;

    try {
      dispatch(setLoading(true));
      const response = await dotnetApiClient().get(
        `/uiformpayload/user/${userId}/history/10`
      );
      if (!response?.data) {
        dispatch(
          notificationFail(response?.data?.message || "Data not  found")
        );
      } else {
        dispatch(setLoading(false));
        return response;
      }
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(notificationFail("Data not  found"));
    }
  }
);
export const getUiFormPayloadData: any = createAsyncThunk(
  "replenish",
  async (_request, { dispatch }) => {
    const { userId, uiFormDataId }: any = _request;

    try {
      dispatch(setLoading(true));
      const response = await dotnetApiClient().get(
        `/uiformpayload/user/${userId}/values/${uiFormDataId}`
      );

      if (!response?.data) {
        dispatch(
          notificationFail(response?.data?.message || "Data not  found")
        );
      } else {
        dispatch(setLoading(false));
        return response;
      }
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(notificationFail("Data not  found"));
    }
  }
);
