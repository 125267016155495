import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../store/store";
import { getVerifyAuth } from "../store/thunk/authThunk";
import { useEffect } from "react";
import LoaderComponent from "../components/loader";
import { useAuth0 } from "@auth0/auth0-react";
import { setLoading } from "../store/slices/notificationSlice";
import { Typography } from "@mui/material";
import RightSVG from "../assets/images/RightSVG.svg";
import Approvalimage from "../assets/images/100papprovalimage.png";
import Moneybackimage from "../assets/images/moneybackimage.png";
import Logo from "../assets/images/logo.svg";
import queryString from "query-string";

const LoginComponent = () => {
  const dispatch: any = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const {
    loginWithRedirect,
    user,
    isAuthenticated,
    isLoading,
    getAccessTokenSilently,
  } = useAuth0();
  const { authConfig } = useAppSelector((state: any) => state.authReducer);
  const { code } = queryString.parse(location.search);

  useEffect(() => {
    if (code && code === "startforfree") {
      navigate("/home");
    }
    if (code && code === "login") {
      loginWithRedirect();
    }
    if (code && code === "signup") {
      loginWithRedirect({
        authorizationParams: {
          screen_hint: "signUp",
        },
      });
    }
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      const fetchToken = async () => {
        try {
          dispatch(setLoading(true));
          const token = await getAccessTokenSilently({
            authorizationParams: {
              redirect_uri: window.location.origin,
              scope: "openid profile email read:current_user",
            },
          });

          dispatch(setLoading(false));
          if (token) {
            localStorage.setItem("accessToken", token);
            localStorage.setItem("userData", JSON.stringify(user));
            localStorage.setItem("csrfToken", authConfig?.csrfToken);
            if (user?.email_verified) {
              dispatch(getVerifyAuth(token)).then((res: any) => {
                if (res.status == 200 && res?.payload?.data?.isVerified) {
                  navigate("/home");
                }
              });
            } else {
              navigate("/verify");
            }
          }

          // You can also make an authenticated API request here.
        } catch (e) {
          dispatch(setLoading(false));
          console.error("Error fetching access token:", e);
        }
      };

      fetchToken();
    } else {
      if (!code) {
        loginWithRedirect();
      }
    }
  }, [isAuthenticated, getAccessTokenSilently]);

  const loading = useAppSelector(
    (state: any) => state.notificationReducer.loading
  );

  return (
    <>
      <LoaderComponent />

      {/* Previous Login page */}

      {/* <section className="gradient-form h-screen bg-neutral-200">
        <div className=" h-screen">
          <div className="flex h-full flex-wrap items-center justify-center text-neutral-800">
            <div className="w-full h-full">
              <div className="block bg-white shadow-lg h-full md:overflow-unset overflow-auto">
                <div className="md:flex block  items-center h-full">
                  <div className="bg-[#fff] md:w-7/12 md:h-full h-auto">
                    <div className="flex flex-col h-full">
                      <div className="2xl:px-20 md:px-16 px-12 py-6 border-b-[#ddd] border-b border-solid w-full">
                        <img className="2xl:w-56 w-44" src={Logo} alt="logo" />
                      </div>
                      <div className="2xl:px-20 md:px-16 px-12  md:py-0 py-3 flex justify-center flex-col h-full">
                        <h4 className="2xl:mb-16 xl:mb-8 mb-6 font-bold mt-4 lg-0 md:text-2xl text-lg 2xl:text-5xl">
                          Go with confidence! The USAPath guarantee.
                        </h4>
                        <ul className="p-0">
                          <li className="2xl:mb-11 mb-7 flex items-center font-semibold md:text-lg text-sm 2xl:text-3xl">
                            <img
                              src={RightSVG}
                              alt="RightSVG"
                              // width={30}
                              className="2xl:w-9 w-6"
                              style={{ marginRight: "18px" }}
                            />
                            Software designed by leading immigration law firm
                          </li>
                          <li className="2xl:mb-11 mb-7 flex items-center font-semibold md:text-lg text-sm 2xl:text-3xl">
                            <img
                              src={RightSVG}
                              alt="RightSVG"
                              // width={30}
                              className="2xl:w-9 w-6"
                              style={{ marginRight: "18px" }}
                            />
                            Live customer support and on demand answers to any questions
                          </li>
                          <li className="2xl:mb-24 mb-12 flex items-center font-semibold md:text-lg text-sm 2xl:text-3xl">
                            <img
                              src={RightSVG}
                              alt="RightSVG"
                              // width={30}
                              className="2xl:w-9 w-6"
                              style={{ marginRight: "18px" }}
                            />
                            Peace of mind with real-time form preview (100% free)  and built-in checks for errors
                          </li>
                        </ul>
                        <ul className="flex p-0">
                          <li className="px-2">
                            <img
                              src={Moneybackimage}
                              alt="Moneybackimage"
                              className="2xl:w-56 w-28 mr-10"
                            // width={100}
                            />
                          </li>
                          <li className="px-2">
                            <img
                              src={Approvalimage}
                              alt="Approvalimage"
                              className="2xl:w-56 w-28"
                            // width={100}
                            />
                          </li>
                        </ul>
                      </div>
                      <p className="block w-full 2xl:px-20 md:px-16 px-12 py-4 2xl:text-3xl text-xl text-[#9CA3AF]">
                        © 2023 USA Path | All rights reserved
                      </p>
                    </div>
                  </div>
                  <div className="px-4 md:px-0 md:w-5/12 h-full bg-[#1c2333] ">
                    <form className="flex flex-col justify-center h-full">
                      <Typography
                        variant="h1"
                        textAlign="center"
                        marginBottom={2}
                        fontWeight={600}
                        color="#fff"
                        sx={{
                          fontSize: { xl: "40px", md: "25px", xs: "18px" },
                        }}
                      >
                        Log in or create your <br />
                        account to get <br />
                        started
                      </Typography>
                      <div className="mb-12 pb-1 pt-1 text-center flex gap-2 justify-center flex-col md:flex-row">
                        <button
                          disabled={isLoading}
                          className="mb-3 inline-block px-6 py-2.5 2xl:text-xl md:text-sm font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_rgba(0,0,0,0.2)] transition duration-150 ease-in-out hover:shadow-[0_8px_9px_-4px_rgba(0,0,0,0.1),0_4px_18px_0_rgba(0,0,0,0.2)] focus:shadow-[0_8px_9px_-4px_rgba(0,0,0,0.1),0_4px_18px_0_rgba(0,0,0,0.2)] focus:outline-none focus:ring-0 active:shadow-[0_8px_9px_-4px_rgba(0,0,0,0.1),0_4px_18px_0_rgba(0,0,0,0.2)] 2xl:w-3/12 md:w-4/12 sm:w-5/12 sm:self-center bg-blue-600	rounded-full 2xl:h-16 h-11"
                          type="button"
                          data-te-ripple-init
                          data-te-ripple-color="light"
                          onClick={() => loginWithRedirect()}
                        >
                          Log in
                        </button>
                        <button
                          disabled={isLoading}
                          className="mb-3 inline-block px-6 py-2.5 2xl:text-xl md:text-sm sm:text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_rgba(0,0,0,0.2)] transition duration-150 ease-in-out hover:shadow-[0_8px_9px_-4px_rgba(0,0,0,0.1),0_4px_18px_0_rgba(0,0,0,0.2)] focus:shadow-[0_8px_9px_-4px_rgba(0,0,0,0.1),0_4px_18px_0_rgba(0,0,0,0.2)] focus:outline-none focus:ring-0 active:shadow-[0_8px_9px_-4px_rgba(0,0,0,0.1),0_4px_18px_0_rgba(0,0,0,0.2)] 2xl:w-3/12 md:w-4/12 sm:w-5/12 sm:self-center  bg-blue-600	rounded-full 2xl:h-16 h-11 "
                          type="button"
                          data-te-ripple-init
                          data-te-ripple-color="light"
                          onClick={() => loginWithRedirect()}
                        >
                          Sign Up
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
    </>
  );
};

export default LoginComponent;
