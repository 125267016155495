import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import SideLogo from "../../assets/images/logo-white.png";
import VerifySidebarComponent from "../VerifySidebar";

const LayoutComponent = ({ children }: any) => {
  const navigate = useNavigate();
  const accessToken = localStorage.getItem("accessToken") || null;
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);

  useEffect(() => {
    if (!accessToken) {
      navigate("/");
    }
  }, []);

  return (
    <div>
      <div className="flex md:hidden justify-between items-center p-2.5 bg-[#111827] h-[77px]">
        <img src={SideLogo} alt="Logo" />

        <button
          data-drawer-target="sidebar-multi-level-sidebar"
          data-drawer-toggle="sidebar-multi-level-sidebar"
          aria-controls="sidebar-multi-level-sidebar"
          type="button"
          className="inline-flex items-center p-2 text-sm text-white hover:text-black rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
          onClick={() => setIsSidebarVisible(!isSidebarVisible)}
        >
          <svg
            className="w-6 h-6"
            aria-hidden="true"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              clipRule="evenodd"
              fillRule="evenodd"
              d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
            ></path>
          </svg>
        </button>
      </div>
      <div>
        <div
          className={`main ml-auto fixed w-full overflow-hidden ${
            isSidebarVisible ? "sideBarHide" : "sideBarShow"
          }`}
        >
          <VerifySidebarComponent />
          <div
            className={`main mainContentSection h-screen ${
              isSidebarVisible ? "sideBarHide" : "sideBarShow"
            }`}
          >
            {isSidebarVisible && (
              <div
                style={{
                  background: "rgba(0, 0, 0, 0.5)",
                  zIndex: 1000,
                  position: "fixed",
                  width: "100%",
                  height: "100%",
                }}
                onClick={() => setIsSidebarVisible(!isSidebarVisible)}
              />
            )}
            <div className={"closeFAQWdith"}>
              <header className="flex items-center min-h-[49px] justify-center bg-[#fff] md:px-[2rem] md:py-[25px] md:pb-[5px] 2xl:pb-[25px] border-b border-solid border-[#E5E7EB]"></header>
              <div className="h-full mainInnerContent">{children}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LayoutComponent;
