import { useAuth0 } from "@auth0/auth0-react";
import { Navigate } from "react-router";
import { anonymousUser } from "../../helper/service";

function PrivateRoute(props: any) {
  const { children } = props;
  const { logout } = useAuth0();

  const accessToken = localStorage.getItem("accessToken")
    ? localStorage.getItem("accessToken")
    : null;
  const userData: any = localStorage.getItem("userData")
    ? localStorage.getItem("userData")
    : null;
  const isAdmin = localStorage.getItem("role") == "superAdmin" ? true : null;

  let localData: any = localStorage.getItem("bootstrapData");
  const bootstrapData: any = JSON.parse(localData);

  if(anonymousUser()){
    return children;
  }

  if (!accessToken) {
    logout({ logoutParams: { returnTo: window.location.origin } });
  }

  const userdata = JSON.parse(userData);
  if (!bootstrapData?.isVerified || !userdata?.email_verified) {
    return <Navigate to="/verify" />;
  }
  return accessToken && userData ? children : <Navigate to="/home" />;
}

export default PrivateRoute;
