import { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import MUIDataTable from "mui-datatables";
import { Delete, Visibility } from "@mui/icons-material";

import DeleteComponent from "./deleteComponent";
import ViewComponent from "./viewComponent";
import DisabledComponent from "./disabledComponent";
import { useAppDispatch, useAppSelector, RootState } from "../../store/store";
import { deleteSingleUser } from "../../store/thunk/userThunk";
import moment from "moment";

interface UserDataType {
  id: number;
  auth0Email: string;
  auth0Name: string;
  auth0Nickname: string;
  auth0Subject: string;
  createDtUtc: Date;
  isDeleted: boolean;
  isDisabled: boolean;
  isVerified: boolean;
  lastLoginDtUtc: Date;
  progressPercentage: number;
  userType: string;
  [key: string]: any; // Index signature to accept any property name as a string
}

const TableComponent = () => {
  const dispatch = useAppDispatch();

  const { usersList } = useAppSelector(
    (state: RootState) => state?.userReducer
  );

  const [currentPage, setCurrentPage] = useState(1);
  const [userData, setUserData] = useState<UserDataType[]>([]);
  const [isDelete, setIsDelete] = useState<boolean>(false);
  const [isView, setIsView] = useState<boolean>(false);
  const [isDisable, setIsDisable] = useState<boolean>(false);
  const [userId, setUserId] = useState<number>();
  const [deleteId, setDeleteId] = useState<number>();
  const [isCheckedToggle, setIsCheckedToggle] = useState<boolean>();
  const [disableUSerId, setDisableUSerId] = useState<number>();
  const [singleUser, setSingleUser] = useState<UserDataType>();

  useEffect(() => {
    if (usersList) {
      setUserData(usersList);
    }
  }, [usersList]);

  const handleView = (val: number) => {
    setIsView(true);
    setUserId(val);
  };

  const onUserDisabled = (
    userId: number,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const isChecked = e.target.checked;
    setIsCheckedToggle(isChecked);
    setIsDisable(true);
    setDisableUSerId(userId);
    setSingleUser(
      userData && userData?.find((obj: UserDataType) => obj?.id === userId)
    );
  };

  const onChangeField = (
    event: React.ChangeEvent<HTMLInputElement>,
    users: number
  ) => {
    const { value } = event.target;
    const singleUser =
      userData && userData?.find((obj: UserDataType) => obj?.id === users);
    const sendData = {
      id: singleUser?.id,
      isDeleted: singleUser?.isDeleted,
      isDisabled: singleUser?.isDisabled,
      userType: value,
    };
    dispatch(deleteSingleUser({ sendData }));
  };

  const generateColumn = (name: string, label: string, options = {}) => ({
    name,
    label,
    options: {
      sort: true,
      ...options,
    },
  });

  const commonOptions = {
    setCellProps: () => ({ style: { minWidth: "150px" } }),
  };

  const columns = [
    generateColumn("id", "Id"),
    generateColumn("auth0Nickname", "Name"),
    generateColumn("auth0Email", "Email"),
    generateColumn("userType", "Usertype"),
    generateColumn("progressPercentage", "Progress %", {
      ...commonOptions,
    }),
    generateColumn("stripeStatus", "Stripe Status"),
    generateColumn("isVerified", "Verified", {
      customBodyRender: (value: boolean) => (
        <span
          className={`bg-${value ? "green" : "yellow"}-100 text-${
            value ? "green" : "yellow"
          }-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-${
            value ? "green" : "yellow"
          }-300 dark:text-${value ? "green" : "yellow"}-900`}
        >
          {value ? "Verified" : "Unverified"}
        </span>
      ),
      ...commonOptions,
    }),
    generateColumn("isDisabled", "Disabled", {
      customBodyRender: (value: boolean) => (
        <Typography sx={{ fontWeight: "700" }}>
          {value ? "Yes" : "No"}
        </Typography>
      ),
    }),
    generateColumn("createDtUtc", "Created at", {
      customBodyRender: (value: Date) => (
        <span>{moment(value).format("MM-DD-YYYY")}</span>
      ),
      ...commonOptions,
    }),
    generateColumn("lastLoginDtUtc", "Last login at", {
      customBodyRender: (value: Date) => (
        <span>{moment(value).format("MM-DD-YYYY")}</span>
      ),
      ...commonOptions,
    }),
    {
      name: "id",
      label: "Action",
      options: {
        sort: false,
        customBodyRender: (value: number) => (
          <div>
            <button onClick={() => setDeleteState(value)}>
              <Delete />
            </button>
            <button onClick={() => handleView(value)}>
              <Visibility />
            </button>
          </div>
        ),
      },
    },
  ];

  const options = {
    sort: true,
    filter: false,
    print: false,
    download: false,
    search: false,
    viewColumns: false,
    // serverSide: true,
    rowsPerPageOptions: [],
    page: currentPage > 1 ? currentPage - 1 : 0,
    count: usersList?.length,
    rowsPerPage: 10,
    selectableRowsHideCheckboxes: true,
    pagination: true,
  };

  const handleSort = (column: string, order: string) => {
    const sortedData = [...userData];
    sortedData?.sort((a, b) => {
      const aValue = a[column];
      const bValue = b[column];

      if (order === "asc") {
        return aValue < bValue ? -1 : 1;
      } else {
        return bValue < aValue ? -1 : 1;
      }
    });

    // Update the state with the sorted data
    setUserData(sortedData);
  };

  const setDeleteState = (val: number) => {
    setIsDelete(true);
    setDeleteId(val);
    setSingleUser(
      userData && userData?.find((obj: UserDataType) => obj?.id === val)
    );
  };

  const handleSearchChange = (searchText: string | null) => {
    const searchedUsers = searchText
      ? usersList.filter((user: UserDataType) =>
          user.auth0Email.includes(searchText)
        )
      : usersList;

    setUserData(searchedUsers);
  };

  const headerElement: any = document.getElementsByTagName("header");
  const bannerElement: any = document.getElementsByClassName("banner-height");

  const headerHeight = bannerElement
    ? bannerElement[0]?.clientHeight + headerElement[0]?.clientHeight
    : headerElement[0]?.clientHeight || 0;

  return (
    <>
      <div
        className="table-user-list"
        style={{
          height: `calc(100vh - ${headerHeight}px - 40px )`,
          overflowY: "scroll",
        }}
      >
        <MUIDataTable
          title={"Users List"}
          data={userData}
          columns={columns}
          options={{
            ...options,
            search: true,
            onColumnSortChange: handleSort,
            onSearchChange: handleSearchChange,
          }}
        />
      </div>
      {isDisable && (
        <DisabledComponent
          isDisable={isDisable}
          setIsDisable={setIsDisable}
          disableUSerId={disableUSerId}
          singleUser={singleUser}
          isCheckedToggle={isCheckedToggle}
        />
      )}
      {isDelete && (
        <DeleteComponent
          isDeletePopup={isDelete}
          setIsDelete={setIsDelete}
          deleteId={deleteId}
          singleUser={singleUser}
        />
      )}

      {isView && (
        <ViewComponent
          isViewPopup={isView}
          setIsView={setIsView}
          userId={userId}
          setDeleteState={setDeleteState}
          onUserDisabled={onUserDisabled}
          onChangeField={onChangeField}
        />
      )}
    </>
  );
};

export default TableComponent;
